* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family:  Arial, sans-serif;
   font-weight: 300;
}

a, a:link, a:visisted {
  color: white;
}

.typed-cursor {
  display: none;
}
